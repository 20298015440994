.react-tagsinput {
  background-color: #fff;
  border: 1px solid #c0c0c0;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  border-radius: 4px;
}

.react-tagsinput--focused {
  border-color: #6800f4;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font: inherit;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  font-size: 1rem;
}
