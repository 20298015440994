body {
  overflow: hidden;
}

.MuiButton-startIcon {
  margin-inline-start: -4px !important;
  margin-inline-end: 8px !important;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}
